export interface Organizacion {
  estado: string
  name: string
  tipo?: string
  tags: string
  phones?: string
  web?: string
  addres?: string
}
export const directorio: Organizacion[] = [
  {
    name: "Alianza Nacional por el Derecho a decidir",
    estado: "Cobertura Nacional",
    tipo: "Organismo gubernamental",
    tags: "Interrupción legal del embarazo",
    phones: "800 800 8821",
    web: "https://andar.org.mx/puedes-elegir/#violencia",
  },
  {
    name: "Comisión 1, 2, 3 por nosotras",
    estado: "Cobertura Nacional",
    tipo: "Sociedad Civil",
    tags: "Asesoría legal",
    web: "https://docs.google.com/forms/d/e/1FAIpQLSdBfuGxJ99_1E-ZtiLwFOiUp3JaEzZWTUDZTxCGvNQleed08g/viewform",
  },
  {
    name: "GIRE - Red Nacional por la Justicia Reproductiva",
    estado: "Cobertura Nacional",
    tipo: "Sociedad Civil",
    tags: "Interrupción legal del embarazo",
    phones: "55 5658 6684",
    web: "https://gire.org.mx/gire-quienes-somos/",
  },
  {
    name: "Red por los derechos sexuales y reproductivos en México (ddeser)",
    estado: "Cobertura Nacional",
    tipo: "Sociedad Civil",
    tags: "Interrupción legal del embarazo",
    phones: "teacompanamos@ddeser.org",
    web: "https://www.ddeser.org/",
  },
  {
    name: "IPAS - México",
    estado: "Cobertura Nacional",
    tipo: "Sociedad Civil",
    tags: "Interrupción legal del embarazo",
    phones: "https://ipasmexico.org/contactanos/",
    web: "https://ipasmexico.org/category/recursos/",
  },
  {
    name: 'Centro de Apoyo a la Mujer"Margarita Magón"',
    estado: "Cobertura Nacional",
    tipo: "Sociedad Civil",
    tags: "Atención psicológica, asesoría legal",
    phones: "55 5519 5845",
    web: "https://www.facebook.com/pages/category/Organization/Centro-De-Apoyo-a-La-Mujer-CAM-Margarita-Mag%C3%B3n-AC-1470777906532540/",
  },
  {
    name: "Vereda Themis",
    estado: "Cobertura Nacional",
    tipo: "Sociedad Civil",
    tags: "Atención psicológica, asesoría legal",
    phones: "55 5341 6570,  55  5396 5586, 55 5342 2767",
    web: "http://www.veredathemis.org.mx/",
  },
  {
    name: "Pro Ayuda a la Mujer Fundación Origen",
    estado: "Cobertura Nacional",
    tipo: "Sociedad Civil",
    tags: "Atención psicológica, asesoría legal, atención médica",
    phones: "800 015 1617, 55 5520 4427 y 55 5552 01555",
    web: "https://www.origenac.org/",
  },
  {
    name: "Red Nacional de Refugios",
    estado: "Cobertura Nacional",
    tipo: "Sociedad Civil",
    tags: "Refugio",
    phones: "Tel: 55 5243 6432Tel. 800 822 4460",
    web: "https://www.rednacionalderefugios.org.mx/",
  },
  {
    name: "VICTIMATEL",
    estado: "Cobertura Nacional",
    tags: "Atención psicológica, asesoría legal",
    phones: "55 5575 5461",
  },
  {
    name: "Línea SAPTEL.",
    estado: "Cobertura Nacional",
    tags: "Atención psicológica",
    phones: "55 5259 8121.",
    web: "http://www.saptel.org.mx/que_es.html",
  },
  {
    name: "Línea Mujeres de la Secretaría de las Mujeres",
    estado: "Cobertura Nacional",
    tags: "Atención psicológica",
    phones: "Teléfono: 55 5658 1111 (opción 3)",
    web: "http: //locatel.cdmx.gob.mx/mujeres/",
  },
  {
    name: "Centros de Justicia para las Mujeres",
    estado: "Cobertura Nacional",
    tags: "Atención psicológica, asesoría legal",
    web: "https://www.gob.mx/cms/uploads/attachment/file/469801/Directorio_Centros_de_Justicia_para_las_Mujeres_2019.pdf",
  },
  {
    name: "Comisiones Estatales de Derechos Humanos",
    estado: "Cobertura Nacional",
    tags: "Atención a quejas, asesoría legal",
    web: "https://www.gob.mx/cms/uploads/attachment/file/394589/Directorio_Comisiones_Derechos_Humanos_2018.pdf",
  },
  {
    name: "Líneas Telefónicas e Institutos Estatales de Atención a la Violencia contra las Mujeres",
    estado: "Cobertura Nacional",
    tags: "Atención psicológica, asesoría legal",
    web: "https://www.gob.mx/cms/uploads/attachment/file/394591/Directorio_Institutos_Estatales_y_Lineas_2018.pdf",
  },
  {
    name: "Fiscalías Generales de Justicia",
    estado: "Cobertura Nacional",
    tags: "Atención a quejas",
    web: "https://www.gob.mx/cms/uploads/attachment/file/395910/Directorio_Procuraduri_as-Fiscali_as_2018.pdf",
  },
  {
    estado: "Aguascalientes",
    name: "MUJER CONTEMPORANEA, A. C.",
    tipo: "Sociedad Civil",
    tags: "Atención psicológica, asesoría legal",
    phones: "44 9918 8176 o 44 9918 1752",
    addres: "ZARAGOZA NO. 601 COL. CENTRO, CP 20000, AGUASCALIENTES",
  },
  {
    name: "Comisión Estatal de Derechos Humanos de Aguascalientes",
    estado: "Aguascalientes",
    tipo: "Organismo gubernamental",
    tags: "Atención a quejas",
    phones: "44 9140 7870, 44 9140 7846 y 44 9140 7860",
    web: "http://www.dhags.org/",
    addres:
      "República de Perú No. 502, Fraccionamiento Santa Elena, C.P. 20271, Aguascalientes, Aguascalientes",
  },
  {
    name: "Instituto Aguascalentense de las Mujeres",
    estado: "Aguascalientes",
    tipo: "Organismo gubernamental",
    tags: "Asesoría legal, atención psicológica, atienden infancias",
    phones: "44 9910 2580 o (449) 910 2128 ext. 1370",
    web: "http://www.aguascalientes.gob.mx/IAM/",
    addres: "V. Manuel Gómez Morín s/n Col. Ferronales , C.P. 20180",
  },
  {
    estado: "Baja California",
    name: "INMUJER Baja California",
    tipo: "Organismo gubernamental",
    tags: "Atención psicológica, asesoría legal",
    phones:
      "Mexicali: 68 6558 6364 y 68 6557 5495 Tijuana: 66 4608 0888 Ensenada: 64 6248 0873 y 64 6248 0862",
    web: "http://www.bajacalifornia.gob.mx/inmujer/",
    addres:
      "Miguel Negrete, No. 1711, Col. Nueva, Mexicali, Baja California, C.P. 21000",
  },
  {
    name: "Procuraduría de los Derechos Humanos y Protección Ciudadana del estado de Baja California",
    estado: "Baja California",
    tipo: "Organismo gubernamental",
    tags: "Atención a quejas",
    phones: "66 4973-2373 al 77 Lada Nacional Sin Costo: 800 026 7342",
    web: "http://www.derechoshumanosbc.org/",
    addres:
      "Av. José Gorostiza N° 1151 entre Vía Rápida Oriente y Paseo Centenario, atrás de CANACO",
  },
  {
    name: "Comisión Estatal de Derechos Humanos de Baja California Sur",
    estado: "Baja California Sur",
    tipo: "Organismo gubernamental",
    tags: "Atención a quejas",
    phones:
      "61 2123 1404, 61 2123 1404 y 61 2123 2332, Lada Nacional Sin Costo: 800 690 83 00",
    web: "https://www.derechoshumanosbcs.org.mx/",
    addres:
      "Blvd. Constituyentes de 1975 e/ Calle Cabrilla y Calle Tiburón, Fraccionamiento Fidepaz, C. P. 23090, La Paz, Baja California Sur",
  },
  {
    estado: "Campeche",
    name: "Comisión Estatal de Derechos Humanos de Campeche",
    tipo: "Organismo gubernamental",
    tags: "Asesoría legal",
    phones: "98 1816 0897",
    web: "http://codhecam.org.mx/",
    addres:
      "Prolongación 59 No. 6, Col. Centro, C.P. 24000, Campeche, Campeche",
  },
  {
    name: "Instituto de la Mujer de Campeche",
    estado: "Campeche",
    tipo: "Organismo gubernamental",
    tags: "Asesoría legal, atención psicológica, atención médica",
    phones: "98 1811 6086",
    web: "http://imecam.gob.mx/",
    addres:
      "Av. Adolfo López Mateos No. 250 altos, Colonia San Román, Campeche, México, C.P. 24040.",
  },
  {
    name: "Observatorio de Violencia Social y de Género A.C.",
    estado: "Campeche",
    tipo: "Sociedad Civil",
    tags: "Asesoría legal, atención psicológica",
    phones: "98 1816 8948",
    web: "https://www.facebook.com/ObservatorioCampeche/",
    addres: "Calle 65 No. 53, entre 14 y 16, Centro, Campeche, Campeche",
  },
  {
    estado: "Coahuila",
    name: "Acompañantes Laguna - Ddeser",
    tipo: "Sociedad Civil",
    tags: "Interrupción legal del embarazo",
    phones: "87 1408 4396 / 87 1188 8694 / 87 1164 4539 / 87 1188 8694",
    web: "https://www.facebook.com/acompananteslaguna",
  },
  {
    name: "Centro de Apoyo Opciones Dignas A.C.",
    estado: "Coahuila",
    tipo: "Sociedad Civil",
    tags: "Refugio, asesoría legal, atención médica, atención psicológica",
    phones: "87 7888 0789",
    web: "http://www.opcionesdignas.org/index.html",
    addres:
      "Privada Ayuntamiento No. 625 D, Colonia Aeropuerto Acuña, Coahuila",
  },
  {
    name: "Comisión Estatal de Derechos Humanos del Estado de Coahuila",
    estado: "Coahuila",
    tipo: "Organismo gubernamental",
    tags: "Atención a quejas",
    phones: "84 4416 6196 y 84 4416 2050",
    web: "http://cdhec.org.mx/",
    addres:
      "Blvd. Venustiano Carranza No. 1623, Col. República Poniente, entre Chihuahua y Colima, C.P. 25280, Saltillo, Coahuila",
  },
  {
    name: "Instituto Coahuilense de las Mujeres",
    estado: "Coahuila",
    tipo: "Organismo gubernamental",
    tags: "Asesoría legal, atención psicológica",
    phones: "84 4698 1080",
    web: "https://www.icmujeres.gob.mx/",
    addres:
      "Boulevard Coahuila, Av. Fundadores y Centenario de Torreón Col. El Toreo, Saltillo, Coahuila.",
  },
  {
    name: "Red de Mujeres de La Laguna",
    estado: "Coahuila",
    tipo: "Sociedad Civil",
    tags: "Interrupción legal del embarazo",
    web: "https://www.facebook.com/RedMujeresLaguna.LagunalibredeViolencia/",
  },
  {
    name: "Aborto Seguro",
    estado: "Coahuila",
    tipo: "Organismo gubernamental",
    tags: "Interrupción legal del embarazo",
    web: "https://www.facebook.com/abortosegurocoahuila",
    addres: "Centro de Salud Abastos. Torreón, Coahuila",
  },
  {
    estado: "Colima",
    name: "Comisión Estatal de Derechos Humanos del Estado de Colima",
    tipo: "Organismo gubernamental",
    tags: "Atención a quejas",
    phones:
      "31 2312 2994, 31 4 77 95, 31 4 90 84, 31 4 71 86 , Lada sin costo 800 696 7672, Emergencias las 24 hrs  (044) 31 2155 1333",
    web: "https://cdhcolima.org.mx/",
    addres: "Degollado No. 79, Col. Centro, C.P. 28000, Colima, Colima",
  },
  {
    name: "Instituto Colimense de las Mujeres",
    estado: "Colima",
    tipo: "Organismo gubernamental",
    tags: "Atención a quejas",
    phones: "800 836 4077",
    web: "https://www.col.gob.mx/institutodelasmujeres",
    addres:
      "Complejo Administrativo del Gobierno del Estado, Edificio A, Planta Baja, 3er anillo Periférico, General Marcelino García Barragán s/n, , Colonia el Diezmo, Colima, Colima, C.P. 28010",
  },
  {
    estado: "Chiapas",
    name: "Secretaría de Igualdad de Género de Chiapas (SEIGEN)",
    tipo: "Organismo gubernamental",
    tags: "Asesoría legal, atención psicológica",
    phones: "96 1264 0639 y 96 1264 0643 - Línea digital SEIGEN: 96 1462 9340",
    web: "http://seigen.chiapas.gob.mx/paimef#contacto",
    addres:
      "Blvd. Andrés Serra Rojas, Esq. con Lib. Norte S/N Anexo 1B Torre Chiapas, Col. El Retiro. C.P. 29040, Tuxtla Gutiérrez, Chiapas",
  },
  {
    name: "Comisión Estatal de los Derechos Humanos",
    estado: "Chiapas",
    tipo: "Organismo gubernamental",
    tags: "Atención a quejas",
    phones:
      "Conmutador: 96 1602 8980, 96 1602 8981 Lada sin costo: 800 55 28 242",
    web: "https://cedhchiapas.org/cedh/about-us/",
    addres:
      "Primera Avenida Sur Oriente S/N, Edificio Plaza, 3er y 4to piso, Barrio San Roque Colonia Centro C.P. 29000 Tuxtla Gutiérrez, Chiapas.",
  },
  {
    name: "Comisión Estatal de Derechos Humanos de Chihuahua",
    estado: "Chihuahua",
    tipo: "Organismo gubernamental",
    tags: "Atención a quejas",
    phones: "61 4201 2990, 61 4201 2991 y 61 4201 2992",
    web: "https://cedhchihuahua.org.mx/wp/",
    addres:
      "Décima y Mina No. 1,000, Col. Centro, C.P. 31000, Chihuahua, Chihuahua",
  },
  {
    name: "Centro de Derechos Humanos de las Mujeres (Cedehm)",
    estado: "Chihuahua",
    tipo: "Sociedad Civil",
    tags: "Asesoría legal, atención psicológica",
    phones: "61 4132 9104 y 61 4255 0305",
    web: "https://cedehm.org.mx/es/?fbclid=IwAR373MTYSrHSlcJ0AXNB3YFVuVMk8tYXCNKX81OmgBbfu1o1mulU6GEH7QM#",
    addres:
      "Av. Juárez 4107-B Col. Centro, Chihuahua, Chih., México, C.P. 31000",
  },
  {
    name: "Instituto Chihuahuense de la Mujer",
    estado: "Chihuahua",
    tipo: "Organismo gubernamental",
    tags: "Asesoría legal, atención psicológica",
    phones: "61 4429 3505",
    web: "http://www.institutochihuahuensedelasmujeres.gob.mx/",
    addres:
      "Calle 1° de Mayo No. 1802, Col. Pacífico, Chihuahua, Chihuahua C.P. 31320",
  },
  {
    estado: "Ciudad de México",
    name: "Abogadas de las Mujeres (Secretaría de las Mujeres CDMX)",
    tipo: "Organismo gubernamental",
    tags: "Asesoría legal",
    phones: "55 5658 1111",
    web: "https://www.semujeres.cdmx.gob.mx/abogadas-de-las-mujeres",
  },
  {
    name: "ADIVAC, Asociación para el Desarrollo Integral de Personas Violadas, A. C",
    estado: "Ciudad de México",
    tipo: "Sociedad Civil",
    tags: "Asesoría legal, atención psicológica, atención médica",
    phones: "55 5682 7969 y 55 5547 8639",
    web: "https://www.adivac.org/",
    addres:
      "Salvador Díaz Mirón No. 140, Santa María La Ribera, Cuauhtémoc, Ciudad de México",
  },
  {
    name: "Apis Fundación para la Equidad",
    estado: "Ciudad de México",
    tipo: "Sociedad Civil",
    tags: "Asesoría legal, atención psicológica",
    phones: "55 5539 3917 y 55 5539 0826 y 55 5704 5048",
    web: "https://www.facebook.com/pages/category/Non-Governmental-Organization--NGO-/APIS-Fundaci%C3%B3n-Para-La-Equidad-AC-180887362093303/",
  },
  {
    name: "Asociación de Mujeres Sobrevivientes de Abuso Sexual, MUSAS, A.C.",
    estado: "Ciudad de México",
    tipo: "Sociedad Civil",
    tags: "Atención psicológica",
    phones: "55 5578 9197 y 55 1998 4568",
    web: "http://www.musas.org.mx/",
    addres:
      "Dr. Durán No. 4, Despacho 214, Col. Doctores, Esq. con Eje Central Lázaro Cárdenas, Ciudad de México",
  },
  {
    name: "Casa Fuente de Apoyo a Mujeres Niñas y Niños Víctimas de Violencia",
    estado: "Ciudad de México",
    tipo: "Sociedad Civil",
    tags: "Atención psicológica, atención médica, refugio",
    phones: "55 5994 5993",
    web: "https://www.facebook.com/casafuentedeapoyo/",
    addres:
      "Calle Piedra Prieta No. 4 – 1 Colonia La Cascada, entre Río Mixcoac y Roca, Álvaro Obregón, Ciudad de México, CP 01490",
  },
  {
    name: "Casa Gaviota",
    estado: "Ciudad de México",
    tipo: "Sociedad Civil",
    tags: "Atención psicológica, asesoría legal",
    phones: "55 3096 5189",
    web: "https://www.casagaviota.org/",
  },
  {
    name: "Comisión de Derechos Humanos de la Ciudad de México",
    estado: "Ciudad de México",
    tipo: "Organismo gubernamental",
    tags: "Atención a quejas",
    phones: "55 5229 5600",
    web: "https://cdhcm.org.mx/",
    addres:
      "Av. Universidad No. 1449, Col. Florida, Pueblo de Axotla, C.P. 01030. Alcaldía Álvaro Obregón, Ciudad de México",
  },
  {
    name: "Defensa Jurídica y Educación para Mujeres, Vereda Themis, S.C",
    estado: "Ciudad de México",
    tipo: "Sociedad Civil",
    tags: "Atención psicológica, asesoría legal",
    phones: "55 5341 6570 y 55 5396 5586",
    web: "http://www.veredathemis.org.mx/",
    addres:
      "Av. De los Maestros No. 91-A, int. 14, Col. Agricultura, Deleg. Miguel Hidalgo, Ciudad de México",
  },
  {
    name: "Fundación Diarq",
    estado: "Ciudad de México",
    tipo: "Sociedad Civil",
    tags: "Atención psicológica, asesoría legal",
    phones: "01 800 836 88 80/ 55 5202 7866",
    web: "http://www.fdiarq.org/",
    addres:
      "Ciudad de México, C. Vicente Beristain #22, Vista Alegre, Cuauhtémoc, 06870 Ciudad de México, CDMX",
  },
  {
    name: "Lunas",
    estado: "Ciudad de México",
    tipo: "Organismo gubernamental",
    tags: "Atención psicológica, asesoría legal",
    phones: "55 5512 2836 ext. 502.",
    web: "https://www.semujeres.cdmx.gob.mx/servicios/servicio/lunas",
  },
  {
    name: "Red de abogadas Violeta",
    estado: "Ciudad de México",
    tipo: "Sociedad Civil",
    tags: "Asesoría legal",
    phones: "33 3302 8714 y  33 1011 5368",
    web: "https://abogadasvioleta.com/servicios/",
  },
  {
    name: "Resilio Consultores S.C.",
    estado: "Ciudad de México",
    tipo: "Sociedad Civil",
    tags: "Asesoría legal, atención psicológica",
    web: "https://resilioconsultores.com.mx/",
  },
  {
    name: "Secretaría de las Mujeres CDMX",
    estado: "Ciudad de México",
    tipo: "Organismo gubernamental",
    tags: "Asesoría legal",
    phones: "55 5512 2836",
    web: "https://www.semujeres.cdmx.gob.mx/",
    addres:
      "Av. Morelos 20, Col. Centro, Cuauhtémoc C.P. 06000, Ciudad de México",
  },
  {
    name: "Línea mujeres",
    estado: "Ciudad de México",
    tags: "Atención psicológica",
    phones: "55 5658 1111",
  },
  {
    estado: "Durango",
    name: "Acompañantes Laguna",
    tipo: "Sociedad Civil",
    tags: "Atención psicológica, interrupción legal del embarazo",
    phones: "87 1408 4396 / 87 1188 8694",
    web: "https://www.facebook.com/acompananteslaguna",
  },
  {
    name: "Comisión de Derechos Humanos del Estado de Durango",
    estado: "Durango",
    tipo: "Organismo gubernamental",
    tags: "Atención a quejas",
    phones: "61 8130 1974",
    web: "https://cedh-durango.org.mx/wp/",
    addres:
      "Cerro Gordo No. 32 int. 13, Centro Comercial del Bosque, Fraccionamiento Lomas del Parque, C.P. 34100, Durango, Durango",
  },
  {
    name: "Instituto de la Mujer Duranguense",
    estado: "Durango",
    tipo: "Organismo gubernamental",
    tags: "Asesoría legal, atención psicológica",
    phones: "61 8827 0906",
    web: "http://iemujer.durango.gob.mx/",
    addres: "Zaragoza No.526 Sur, Zona Centro, Durango, Durango C.P. 34000",
  },
  {
    estado: "Estado de México",
    name: "Comisión de Derechos Humanos del Estado de México",
    tipo: "Organismo gubernamental",
    tags: "Asesoría legal, atención a quejas",
    phones: "Lada sin costo los 24 hrs 800 999 4000",
    web: "https://www.codhem.org.mx/LocalUser/codhem.org/htm/index.asp",
    addres:
      "Av. Nicolás San Juan No. 113, Col. Ex Rancho Cuauhtémoc C.P. 50010, Toluca, México",
  },
  {
    name: "Consejo Estatal de la Mujer y Bienestar Social del Estado de México",
    estado: "Estado de México",
    tipo: "Organismo gubernamental",
    tags: "Asesoría legal",
    phones: "800 108 4053",
    web: "https://semujeres.edomex.gob.mx/",
    addres:
      "Av. José María Morelos y Pavón Pte. 809, Col. La Merced, Toluca, Estado de México",
  },
  {
    name: "Fundación Vive 100% Mujer",
    estado: "Estado de México",
    tipo: "Sociedad Civil",
    tags: "Asesoría legal, atención psicológica, refugio",
    phones: "55 5822 2212",
    web: "http://www.vivemujer.org/",
    addres:
      "Carretera Villa Nicolás Romero Nº 31, Casa 30 B, Boulevares de Atizapán, Atizapán de Zaragoza, Edo. de México",
  },
  {
    estado: "Guanajuato",
    name: "ADIVAC, Asociación para el Desarrollo Integral de Personas Violadas, A. C",
    tipo: "Sociedad Civil",
    tags: "Asesoría legal, atención psicológica, atención médica",
    phones: "55 5682 7969 y 55 5547 8639 y Gto:Tel 47 72511847",
    web: "https://www.adivac.org/",
    addres:
      "Salvador Díaz Mirón No. 140, Santa María La Ribera, Cuauhtémoc, Ciudad de México y Gto: Independencia 305San Juan de Dios37370 León, Gto",
  },
  {
    name: "Casa de apoyo a la mujer A.C",
    estado: "Guanajuato",
    tipo: "Sociedad Civil",
    tags: "Asesoría legal, atención psicológica",
    phones: "47 7780 4090",
    web: "https://casadeapoyoalamujer.org.mx/quienes-somos/",
    addres:
      "Casa de Apoyo a la Mujer A.C.Egipto 1005, Los Angeles, 37258 León, Gto.",
  },
  {
    name: "Instituto para las Mujeres Guanajuatenses",
    estado: "Guanajuato",
    tipo: "Organismo gubernamental",
    tags: "Asesoría legal, atención psicológica, refugio",
    phones: "47 3733 5523, 47 3733 3259 y 47 3733 2903",
    web: "https://imug.guanajuato.gob.mx/",
    addres:
      "Plazuela de Cata #1, Col. Mineral de Cata, C.P. 36010 Guanajuato, GTO.",
  },
  {
    name: "Procuraduría de los Derechos Humanos del Estado de Guanajuato",
    estado: "Guanajuato",
    tipo: "Organismo gubernamental",
    tags: "Asesoría legal, atención psicológica, atención a quejas",
    phones: "47 7770 0845, 47 7770 4113, Lada sin costo 800 470 4400",
    web: "https://www.derechoshumanosgto.org.mx/",
    addres:
      "Av. Guty Cárdenas # 1444, Fraccionamiento Puerta San Rafael, León, Guanajuato, CP 37480",
  },
  {
    estado: "Guerrero",
    name: "Centro de Derechos Humanos de la Montaña, Tlachinollan, A.C.",
    tipo: "Sociedad Civil",
    tags: "Asesoría legal",
    phones: "75 7476 1220, 75 476 1200",
    web: "https://www.tlachinollan.org/",
    addres: "Mina No. 77 Col. Centro, Tlapa, Guerrero, México",
  },
  {
    name: "Centro Regional de Defensa de DDHH José María Morelos y Pavón, A.C.",
    estado: "Guerrero",
    tipo: "Sociedad Civil",
    tags: "Asesoría legal",
    phones: "756 104 0745, 74 7471 6587",
    web: "https://www.facebook.com/Centro-de-Defensa-De-Los-Derechos-Humanos-Jose-Maria-Morelos-y-Pav%C3%B3n-1370753579735743/https://www.facebook.com/Centro-de-Defensa-De-Los-Derechos-Humanos-Jose-Maria-Morelos-y-Pav%C3%B3n-1370753579735743/",
    addres:
      "Calle Cuauhtémoc No. 26, Col. Los Ángeles, C.P. 41100, Chilapa de Álvarez, Guerrero",
  },
  {
    name: "Comisión de Derechos Humanos del Estado de Guerrero",
    estado: "Guerrero",
    tipo: "Organismo gubernamental",
    tags: "Atención a quejas",
    phones:
      "74 7494 2000, Lada sin costo 800 710 66 00, 800 716 26 98 y 800 710 66 18",
    web: "http://www.cdheg.org/",
    addres:
      "Edificio Morelos Av. Benito Juárez, esq. con Galo Soberon y Parra, sin número. C.P. 39000, Chilpancingo, Gro.",
  },
  {
    name: "Secretaría de la Mujer de Guerrero",
    estado: "Guerrero",
    tipo: "Organismo gubernamental",
    tags: "Asesoría legal",
    phones: "74 7471 9700",
    web: "http://www.semujer.guerrero.gob.mx/",
    addres:
      "Boulevard René Juárez Cisneros No. 62, Ciudad de los Servicios, C.P 39075, Chilpancingo, Guerrero; México.",
  },
  {
    estado: "Hidalgo",
    name: "Comisión de Derechos Humanos del Estado de Hidalgo",
    tipo: "Organismo gubernamental",
    tags: "Atención a quejas",
    phones:
      "77 17187 144, 77 1718 1696, Lada sin costo 800 717 6596 y whats: 77 1360 0270",
    web: "https://cdhhgo.org/home/",
    addres:
      "Av. Juárez s/n esquina José María Iglesias Colonia Centro Pachuca de Soto, C.P. 42000",
  },
  {
    name: "Instituto Hidalguense de las Mujeres",
    estado: "Hidalgo",
    tipo: "Organismo gubernamental",
    tags: "Asesoría legal, atención psicológica",
    phones: "800 502 2221",
    web: "http://mujeres.hidalgo.gob.mx/",
    addres:
      "Parque Hidalgo No. 103, Piso 2, Col. Centro, Pachuca, Hidalgo, C.P. 42000",
  },
  {
    estado: "Jalisco",
    name: "Colectivo Latinoamericano Emma Goldman A.C.",
    tipo: "Sociedad Civil",
    tags: "Asesoría legal",
    phones: "33 1709 3064",
  },
  {
    name: "Colectivo Mujeres Puerto Vallarta",
    estado: "Jalisco",
    tipo: "Sociedad Civil",
    tags: "Asesoría legal, atención psicológica",
    phones: "33 2159 8110",
  },
  {
    name: "Comisión Estatal de Derechos Humanos de Jalisco",
    estado: "Jalisco",
    tipo: "Organismo gubernamental",
    tags: "Atención a quejas",
    phones: "33 3669 1101",
    web: "http://cedhj.org.mx/",
    addres:
      "Pedro Moreno No. 1616, Col. Americana, C.P. 44160, Guadalajara, Jalisco.",
  },
  {
    name: "Clínica Jurídica Ignacio Ellacuría ITESO",
    estado: "Jalisco",
    tipo: "Sociedad Civil",
    tags: "Asesoría legal, atención psicológica",
    phones: "33 3669 3434",
    web: "https://iteso.mx/web/general/detalle?group_id=3310946",
    addres:
      "Periférico Sur Manuel Gómez Morín # 8585 C.P. 45604 Tlaquepaque, Jalisco, México",
  },
  {
    name: "Red de Mujeres del 05 Distrito Jalisco",
    estado: "Jalisco",
    tipo: "Sociedad Civil",
    tags: "Asesoría Legal",
    phones: "33 2133 1981",
  },
  {
    estado: "Michoacán",
    name: "Comisión Estatal de Derechos Humanos de Michoacán",
    tipo: "Organismo gubernamental",
    tags: "Atención a quejas",
    phones: "44 3113 3500, Lada sin costo 800 6403 188",
    web: "http://cedhmichoacan.org/",
    addres:
      "Fernando Montes de Oca No. 108, Colonia Chapultepec Norte Morelia, Michoacán C.P. 58260",
  },
  {
    name: "Secretaría de Igualdad Sustantiva y Desarrollo de las Mujeres de Michoacán",
    estado: "Michoacán",
    tipo: "Organismo gubernamental",
    tags: "Asesoría legal, atención psicológica",
    phones: "44 3113 6700",
    web: "https://mujer.michoacan.gob.mx/",
    addres: "Av. Lázaro Cárdenas No. 2440, Col. Chapultepec Sur, C.P. 58260,",
  },
  {
    estado: "Morelos",
    name: "Comisión de Derechos Humanos del Estado de Morelos",
    tipo: "Organismo gubernamental",
    tags: "Atención a quejas",
    phones: "77 7322 1600 y 77 7322 1601",
    web: "https://cdhmorelos.org.mx/",
    addres:
      "Hermenegildo Galeana No. 39, Col. Acapatzingo, C.P. 62440, Cuernavaca, Morelos",
  },
  {
    name: "Instituto de la Mujer para el Estado de Morelos",
    estado: "Morelos",
    tipo: "Organismo gubernamental",
    tags: "Asesoría legal, atención psicológica",
    phones: "80 0911 1515, 77 7538 6609",
    web: "https://www.immorelos.org.mx/atencion",
    addres:
      "Boulevard Benito Juárez No. 82, Col. Las Palmas, Cuernavaca, Morelos, C.P. 62050.",
  },
  {
    estado: "Nayarit",
    name: "Comisión de Derechos Humanos para el Estado de Nayarit",
    tipo: "Organismo gubernamental",
    tags: "Atención a quejas",
    phones: "31 1212 5766 y 31 1213 8986",
    web: "http://www.cddhnayarit.org/en",
    addres: "Av. México No. 276 Nte, Col. Centro, 63000 Tepic, Nayarit",
  },
  {
    estado: "Nuevo León",
    name: "Alternativas Pacíficas",
    tipo: "Sociedad Civil",
    tags: "Asesoría legal, atención psicológica, atención médica, refugio",
    phones: "81 8372 9694",
    web: "https://alternativaspacificas.org/",
    addres:
      "Ignacio Zaragoza No. 555, Col. Centro, Monterrey, Nuevo León, C.P. 64000",
  },
  {
    name: "Ciudadanos en Apoyo a los Derechos Humanos (CADHAC)",
    estado: "Nuevo León",
    tipo: "Sociedad Civil",
    tags: "Asesoría legal, atención psicológica",
    phones: "81 8343 5058",
    web: "https://cadhac.org/",
    addres: "Padre Mier No. 617, Centro, C.P. 64000, Monterrey, Nuevo León",
  },
  {
    name: "Instituto Estatal de las Mujeres de Nuevo León",
    estado: "Nuevo León",
    tipo: "Organismo gubernamental",
    tags: "Asesoría legal, atención psicológica",
    phones: "800 003 784 323",
    web: "https://www.nl.gob.mx/mujeres",
  },
  {
    name: "Voces de Mujeres en Acción, A.C.",
    estado: "Nuevo León",
    tipo: "Sociedad civil",
    tags: "Asesoría legal, atención psicológica, interrupción legal del embarazo",
    phones: "818 169 3671",
    web: "https://www.vocesdemujeres.org",
    addres: "Vista Aurora 412, Col. Linda Vista, Guadalupe, N.L.",
  },
  {
    estado: "Oaxaca",
    name: "Comité de Defensa Integral de Derechos Humanos Gobidxha (Codigodh)",
    tipo: "Sociedad Civil",
    tags: "Asesoría legal, atención psicológica",
    phones: "95 1516 0802",
    web: "https://codigodh.org/",
    addres:
      "Oficina Oaxaca, Mártires de Tacubaya No. 205 int.8, Centro, C.P. 68000, Oaxaca",
  },
  {
    name: "Consorcio Oaxaca A.C.",
    estado: "Oaxaca",
    tipo: "Sociedad Civil",
    tags: "Asesoría legal",
    phones: "95 1132 8996",
    web: "https://consorciooaxaca.org/",
    addres:
      "Pensamientos 104Col. Reforma, C.P. 68050Oaxaca de Juárez, Oaxaca, México",
  },
  {
    name: "Defensoría de Derechos Humanos del Pueblo de Oaxaca",
    estado: "Oaxaca",
    tipo: "Organismo gubernamental",
    tags: "Atención a quejas",
    phones: "95 1503 0220",
    web: "https://www.derechoshumanosoaxaca.org/",
    addres:
      "Calle de los Derechos Humanos No. 210, Col. América, C.P. 68050, Oaxaca, Oaxaca",
  },
  {
    name: "Grupo de estudios sobre la mujer Rosario Castellanos (GES Mujer)",
    estado: "Oaxaca",
    tipo: "Sociedad Civil",
    tags: "Asesoría legal, atención psicológica",
    phones: "95 1516 6810",
    web: "https://www.gesmujer.org/sitio/",
    addres:
      "addres:Tercera Privada de Guadalupe Victoria 107, Colonia Libertad. Oaxaca, Oaxaca.",
  },
  {
    name: "Secretaría de las Mujeres de Oaxaca",
    estado: "Oaxaca",
    tipo: "Organismo gubernamental",
    tags: "Asesoría legal, atención psicológica",
    phones: "95 1207 2836",
    web: "https://www.oaxaca.gob.mx/smo/unidad-paimef/",
    addres:
      "Heroica Escuela Naval Militar 221, Reforma, 68050 Oaxaca de Juárez, Oax.",
  },
  {
    estado: "Puebla",
    name: "Comisión de Derechos Humanos del Estado de Puebla",
    tipo: "Organismo gubernamental",
    tags: "atención a quejas",
    phones: "22 2309 4700, Lada sin costo 800 201 01 05",
    web: "https://www.cdhpuebla.org.mx/v1/",
    addres: "5 poniente #339 Col. Centro, C.P. 72000, Puebla, Puebla",
  },
  {
    name: "Fundación Juconi",
    estado: "Puebla",
    tipo: "Sociedad Civil",
    tags: "Atención psicológica",
    phones: "22 2237 9416",
    web: "https://www.juconi.org.mx/",
    addres: "Privada Volcán de Colima No. 2720, Volcanes, Puebla, Puebla",
  },
  {
    name: "Secretaría de Igualdad Sustantiva de Puebla",
    estado: "Puebla",
    tipo: "Organismo gubernamental",
    tags: "Asesoría legal, atención psicológica",
    phones: "22 2303 4800 Ext. 3227 y 3228",
    web: "http://sis.puebla.gob.mx/",
    addres: "Av. 2 Sur, No. 902, Col. Centro, Puebla, C.P. 72000",
  },
  {
    name: "Red de Abogadas Feministas de Puebla",
    estado: "Puebla",
    tipo: "Red",
    tags: "Asesoría legal",
    phones: "22 2579 9672",
    web: "https://www.facebook.com/AbogadasFeministasPueblaTlaxcala/",
  },
  {
    estado: "Querétaro",
    name: "CLEIDH, Colectivo de Litigio Estratégico e Investigación en Derechos Humanos",
    tipo: "Sociedad Civil",
    tags: "Asesoría legal",
    phones: "44 2453 8270 y 44 2831 9212",
    web: "https://www.facebook.com/colectivoleidh/",
  },
  {
    name: "Defensoría de Derechos Humanos de Querétaro",
    estado: "Querétaro",
    tipo: "Organismo gubernamental",
    tags: "Atención a quejas",
    phones:
      "44 2214 0837 y 44 2214 6007, Lada gratuita 800 400 6800, Teléfono de guardia 44 2109 5400",
    web: "https://ddhqro.org/",
    addres: "Hidalgo No. 6, Colonia Centro Histórico",
  },
  {
    name: "Instituto Queretano de la Mujer",
    estado: "Querétaro",
    tipo: "Organismo gubernamental",
    tags: "Asesoría legal, atención psicológica",
    phones: "800 008 3568 y 44 2216 4757",
    web: "http://www.institutoqueretanodelasmujeres.gob.mx/",
    addres: "Mariano Reyes No. 17, Col. Centro, Querétaro, Querétaro.",
  },
  {
    estado: "Quintana Roo",
    name: "Comisión de Derechos Humanos del Estado de Quintana Roo",
    tipo: "Organismo gubernamental",
    tags: "Atención a quejas",
    phones: "98 3832 7090 y 98 3832 7090",
    web: "http://www.derechoshumanosqroo.org.mx/portal/portal/",
    addres:
      "Adolfo López Mateos No. 424, esq. Nápoles, Col Campestre, C.P. 77030, Chetumal, Quintana Roo",
  },
  {
    name: "Eleonora Mendoza A.C.",
    estado: "Quintana Roo",
    tipo: "Sociedad Civil",
    tags: "Asesoría legal, atención psicológica",
    phones: "98 4179 3354",
    web: "https://rutasparafortalecer.org/osc/eleonora-mendoza-a-c/",
    addres:
      "Calle Mirlo Pardo No. 25, lote 4, manzana 26, Fraccionamiento Villas del Sol, Playa del Carmen, Quintan Roo",
  },
  {
    name: "Instituto Quintanarroense de la Mujer - PAIMEF",
    estado: "Quintana Roo",
    tipo: "Organismo gubernamental",
    tags: "Asesoría legal, atención psicológica",
    phones: "98 3833 1323",
    web: "https://qroo.gob.mx/iqm",
    addres: "Av. Juárez 49, Centro, C.P 77000, Chetumal, Quintana Roo.",
  },
  {
    estado: "San Luis Potosí",
    name: "Comisión Estatal de Derechos Humanos de San Luis Potosí",
    tipo: "Organismo gubernamental",
    tags: "Atención a quejas",
    phones: "Lada sin costo 800 2639 955",
    web: "http://www.cedhslp.org.mx/web2",
    addres:
      "Mariano Otero Nº 685, Col. Tequisquiapan, San Luis Potosí, S.L.P. C.P. 78250",
  },
  {
    name: "Instituto de las Mujeres del Estado de San Luis Potosí",
    estado: "San Luis Potosí",
    tipo: "Organismo gubernamental",
    tags: "Asesoría legal, atención psicológica",
    phones: "800 672 1433",
    web: "https://slp.gob.mx/imes/Paginas/Inicio.aspx",
    addres: "Madero No. 305, Zona Centro, C.P. 78000",
  },
  {
    name: "Otra Oportunidad A.C",
    estado: "San Luis Potosí",
    tipo: "Sociedad Civil",
    tags: "Asesoría legal, atención psicológica, atención médica, refugio",
    phones: "44 4814 7231",
    web: "http://www.otraoportunidad.altervista.org/quienes_somos.html",
    addres:
      "3a. Privada de Francisco Javier Mina N° 210 Centro San Luis PotosíSan Luis Potosí 78000",
  },
  {
    estado: "Sinaloa",
    name: "Comisión Estatal de los Derechos Humanos de Sinaloa",
    tipo: "Organismo gubernamental",
    tags: "Atención a quejas",
    phones: "66 7752 2421 y 66 7752 2575",
    web: "https://www.cedhsinaloa.org.mx/",
    addres:
      "Ruperto L. Paliza No. 566 Sur, esquina con Ignacio Ramírez Colonia Miguel Alemán, C.P. 80200, Culiacán, Sinaloa",
  },
  {
    name: "Instituto Sinaloense de las Mujeres",
    estado: "Sinaloa",
    tipo: "Organismo gubernamental",
    tags: "Asesoría legal, atención psicológica",
    phones: "800 966 3866",
    web: "https://ismujeres.gob.mx/",
    addres: "Álvaro Obregón No. 1265 sur, Col Guadalupe, C.P. 80220",
  },
  {
    estado: "Sonora",
    name: "Comisión Estatal de Derechos Humanos de Sonora",
    tipo: "Organismo gubernamental",
    tags: "Atención a quejas",
    phones: "Lada sin costo 800 627 2800",
    web: "https://www.cedhsonora.org.mx/Pagina/public/",
    addres: "Blvd. Luis Encinas y Periférico Poniente, Hermosillo Sonora",
  },
  {
    name: "Instituto Sonorense de la Mujer",
    estado: "Sonora",
    tipo: "Organismo gubernamental",
    tags: "Asesoría legal, atención psicológica",
    phones: "66 2217 4986",
    web: "https://ism.sonora.gob.mx/",
    addres:
      "Centro de Gobierno, Edif. Sonora, 3er nivel, Ala Norte, Blvd. Paseo Río Sonora Comonfort, Col. Villa de Seris",
  },
  {
    estado: "Tabasco",
    name: "Comisión Estatal de los Derechos Humanos de Tabasco",
    tipo: "Organismo gubernamental",
    tags: "Atención a quejas",
    phones:
      "99 3315 3345, 99 3 315 3467, Número de guardia 99 3436 4437, Lada sin costo 800 000 23 34",
    web: "http://www.cedhtabasco.org.mx/",
    addres:
      "Boulevard Adolfo Ruiz Cortines, esquina Prolongación Francisco J. Mina #503Col. Casa Blanca, Villahermosa, Tabasco, México.CP:86060",
  },
  {
    name: "Instituto Estatal de las Mujeres de Tabasco",
    estado: "Tabasco",
    tipo: "Organismo gubernamental",
    tags: "Asesoría legal, atención psicológica",
    phones: "99 3316 6488 ext. 102",
    web: "https://tabasco.gob.mx/iem",
    addres:
      "Calle Antonio Suárez Hernández Núm. 136, col. reforma, c.p. 86035, col. Villa Hermosa",
  },
  {
    name: "LUNAS Libres y Unidas en Nuevas Acciones Sororales A.C",
    estado: "Tabasco",
    tipo: "Sociedad Civil",
    tags: "Asesoría legal, atención psicológica",
    phones: "93 3110 6555",
    web: "https://www.facebook.com/pages/category/Local-Business/LUNAS-AC-248171936126352/",
    addres:
      "Calle Sor Juana Inés de la Cruz, Colonia Centro, Comalcalco, Tabasco",
  },
  {
    estado: "Tamaulipas",
    name: "Comisión de Derechos Humanos del Estado de Tamaulipas",
    tipo: "Organismo gubernamental",
    tags: "Asesoría legal",
    phones: "83 4312 4612",
    web: "http://codhet.org.mx/",
    addres: "Río Guayalejo 223, Zozaya, 87070 Cd Victoria, Tamaulipas",
  },
  {
    name: "Instituto de las Mujeres en Tamaulipas",
    estado: "Tamaulipas",
    tipo: "Organismo gubernamental",
    tags: "Asesoría legal, atención psicológica",
    phones: "83 4110 1449 y 83 4110 1500",
    web: "https://www.tamaulipas.gob.mx/mujeres/",
    addres:
      "Ex fábrica textil de San Manuel S/N, Pueblo de San Miguel Contla, Mpio, de Santa Cruz, Tlax. C.P. 70093.",
  },
  {
    estado: "Tlaxcala",
    name: "Comisión Estatal de Derechos Humanos de Tlaxcala",
    tipo: "Organismo gubernamental",
    tags: "Atención a quejas, asesoría legal",
    phones:
      "24 6462 1630, 24 6462 9160, 24 6462 7595, 24 6462 5184 Lada sin costo: 01800 337 48 62",
    web: "http://cedhtlaxcala.cedhtlax.org.mx/index.php?option=com_content&amp;view=article&amp;id=1:bienvenidos&amp;catid=29:the-cms",
    addres:
      "Av. Arquitectos No. 27 Col. Loma Bonita C.P. 90090 Tlaxcala, Tlaxcala",
  },
  {
    name: "Instituto Estatal de la Mujer de Tlaxcala",
    estado: "Tlaxcala",
    tipo: "Organismo gubernamental",
    tags: "Asesoría legal, atención psicológica",
    phones: "800 838 7073",
    web: "https://mujer.tlaxcala.gob.mx/index.php/atenciones",
    addres:
      "Ex Fábrica de San Manuel s/n, Barrio Nuevo, San Miguel Contla, Santa Cruz Tlaxcala.",
  },
  {
    estado: "Veracruz",
    name: "Centro de Derechos Humanos de los Pueblos del Sur de Veracruz “BETY CARIÑO” A.C.",
    tipo: "Sociedad Civil",
    tags: "Asesoría legal, atención psicológica",
    web: "http://cdhbetycarino.blogspot.com/",
    addres:
      "Zaragoza s/n, Barrio Tercero, C.P. 95940, Tatahuicalpan de Juárez, Veracruz",
  },
  {
    name: "Comisión Estatal de Derechos Humanos de Veracruz",
    estado: "Veracruz",
    tipo: "Organismo gubernamental",
    tags: "Asesoría legal, atención a quejas",
    phones: "22 8141 4300, Lada sin costo 800 260 2200",
    web: "http://www.cedhveracruz.org.mx/cedhv/",
    addres:
      "Pico de Orizaba No. 5 Colonia, Sipeh Animas, 91067, Xalapa-Enríquez, Ver.",
  },
  {
    name: "Instituto Veracruzano de las Mujeres",
    estado: "Veracruz",
    tipo: "Organismo gubernamental",
    tags: "Asesoría legal, atención psicológica",
    phones: "800 906 8537",
    web: "http://www.ivermujeres.gob.mx/",
    addres:
      "Av. Adolfo Ruiz Cortines #1618 Francisco Ferrer Guardia C.P. 91020",
  },
  {
    estado: "Yucatán",
    name: "Comisión de Derechos Humanos del Estado de Yucatán",
    tipo: "Organismo gubernamental",
    tags: "Atención a quejas",
    phones:
      "99 9927 8596, 99 9927 2201, 99 9927 9275, Lada sin costo 800 226 3439",
    web: "https://www.codhey.org/?fbclid=IwAR2IEJBjWMJ3s7o-W42CSjfrhsgEp36ULRSG0ZLBHaybBqZfd0c4Jkps2ak",
    addres:
      "Calle 20 No. 391 A x 31-D y 31-F, Col. Nueva Alemán. C.P. 97146, Mérida, Yucatán",
  },
  {
    name: "Secretaría de las Mujeres de Yucatán",
    estado: "Yucatán",
    tipo: "Organismo gubernamental",
    tags: "Asesoría legal, atención psicológica",
    phones: "800 667 7787 y 99 9925 0543",
    web: "https://tramites.yucatan.gob.mx/semujeres",
    addres:
      "Calle 86 No. 499 «C» altos, Ex penitenciaría Juárez, Centro, Mérida, Yucatán, C.P. 97000.",
  },
  {
    name: "Unidad de Atención Sicológica, Sexológica y Educativa para el Crecimiento Personal, A.C. (UNASSE)",
    estado: "Yucatán",
    tipo: "Sociedad civil",
    tags: "Asesoría legal, atención psicológica",
    phones: "99 9966 7066 y Whatsapp 99 99321 7541",
    web: "https://unasse.org/",
    addres: "Calle 81 No. 486 A X 56 y 58, Centro, CP. 97000 Mérida, Yuc.",
  },
  {
    name: "APIS Sureste, Fundación para la Equidad, A.C.",
    estado: "Yucatán",
    tipo: "Sociedad civil",
    tags: "Asesoría legal, atención psicológica, refugio",
    phones: "99 9988 4048",
    web: "https://apisfundacion.com/serviciosdeatencion/",
  },
  {
    estado: "Zacatecas",
    name: "Comisión Estatal de Derechos Humanos de Zacatecas",
    tipo: "Organismo gubernamental",
    tags: "Atención a quejas",
    phones: "Celular de guardia 49 2124 7730, Lada sin costo 800 624 2727",
    web: "https://cdhezac.org.mx/",
    addres:
      "Av. Jesús Reyes Heroles No. 204-2, Col. Javier Barros Sierra, C.P. 98090, Zacatecas, Zacatecas",
  },
  {
    name: "Secretaría de las Mujeres para el Estado de Zacatecas",
    estado: "Zacatecas",
    tipo: "Organismo gubernamental",
    tags: "Asesoría legal, atención psicológica",
    phones: "800 830 0308",
    web: "https://semujer.zacatecas.gob.mx/direccion-de-los-centros-de-atencion/#1558643027634-eb2aa0f8-b1cb",
    addres:
      "Circuito Cerro del Gato, Edificio K, Piso 1, Col. Ciudad Administrativa, C.P. 98160, Zacatecas, Zacatecas.",
  },
]
